import styled from "@emotion/styled/macro";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/styles";
import EditorItem from "admin/EditorItem";
import { TableLayout, Title } from "admin/EditorItem/layoutComponents";
import { getAadClaims, useAccount } from "auth";
import backend from "backend";
import { formatDistanceToNow, isWithinInterval, parseISO, subDays } from "date-fns";
import { produce } from "immer";
import _ from "lodash";
import React from "react";
import { ReactComponent as LaunchIcon } from "shared/Icons/launch.svg";
import { useLabels } from "shared/helpers/prettyNames";
import schemaFeatures from "./schemaFeatures";
import schemaModels from "./schemaModels";
import useConfVersions from "./useConfVersions";
import {
  ImpactDimensionChangePopup,
  useInvestmentPlanAvailable,
  checkImpactPerDimensionChanged,
} from "./ImpactDimensionChangePopup";

function ConfigurationEditorModel({
  organization,
  orgaizationFrameworkId,
  isDisabled,
  onChange,
  modelSlug,
  data,
  prodData,
  isFeatureAvailable,
  actions,
  saveInProgress,
}) {
  let { data: availableModelVersions = [] } = backend.useData(`v4/dimensions/${data.socketSlug}`);
  if (availableModelVersions.length === 0)
    availableModelVersions = [
      { mode: "prod", jobId: data.modelFwVersion_prod?.jobId },
      { mode: "validation", jobId: data.modelFwVersion_validation?.jobId },
    ];
  const jobIdV1 = availableModelVersions.find((d) => d.mode === data.activeVersion)?.jobId;
  const jobIdV2 = data[`modelFwVersion_${data.activeVersion}`]?.jobId;
  if (jobIdV1 && jobIdV2 && jobIdV1 !== jobIdV2)
    console.error(`Problems in activation. Job ID from activation V1 (${jobIdV1}) and V2 (${jobIdV2}) do not match!`);
  const { data: modelDimensionsOld = [] } = backend.useData(`v4/dimensions/${data.socketSlug}/${data.activeVersion}`);
  const { data: decompositionDimensionsV3 = [] } = backend.useData(
    jobIdV2 !== undefined ? `v3/historical/${modelSlug}/decomposition/dimension-values/${jobIdV2}` : null,
  );
  const { data: mediaDimensionsV3 = [] } = backend.useData(
    jobIdV2 !== undefined ? `v3/historical/${modelSlug}/media/dimension-values/${jobIdV2}` : null,
  );

  const labelDictionary = useLabels(data);
  return (
    <>
      <div style={{ height: 40 }} />
      <EditorItem
        topLevel
        isDisabled={isDisabled}
        schema={schemaModels({
          modelSlug,
          data,
          availableModelVersions,
          organization,
          labelDictionary,
          modelDimensionsOld,
          decompositionDimensionsV3,
          mediaDimensionsV3,
          actions,
          saveInProgress,
        })}
        data={data}
        prodData={prodData}
        onChange={onChange}
      />
    </>
  );
}

export default function ConfigurationEditor({ organization, changesPendingCb }) {
  const theme = useTheme();
  const {
    loading,
    conf,
    prodConf,
    onChange,
    hasPrevDraft,
    prodUpdatedStamp,
    draftUpdatedStamp,
    actions,
    saveInProgress,
    deleteInProgress,
    takeToProdInProgress,
  } = useConfVersions(organization);
  const { loggingIn, isFeatureAvailable, isClaim, selectedModel } = useAccount();
  const isDraft = Boolean(onChange);
  const [internalSelectedModel, setInternalSelectedModel] = React.useState(selectedModel);
  const [openImpactDimensionChangePopup, setOpenImpactDimensionChangePopup] = React.useState(false);
  //data from this hook is only required when draft is open
  const isInvestmentPlanAvailable = useInvestmentPlanAvailable(isDraft ? internalSelectedModel : null);
  const unsavedChanges = Boolean(actions.saveDraft);
  React.useEffect(() => {
    changesPendingCb(unsavedChanges);
  }, [changesPendingCb, unsavedChanges]);

  if (loading) return <LinearProgress />;

  const allModelSlugs = Object.keys(conf.models || {});

  const modelsLabels = Object.assign(
    {},
    ...Object.entries(conf.models || {}).map(([slug, data]) => ({
      [slug]: `${data.brand} ${data.name}`,
    })),
  );
  // using this variable to check if the null values are present in model config or not.
  let nullValuesInConfig = false;
  const selectedModelObject = selectedModel ? conf.models[internalSelectedModel] : null;
  const impactPerDimension =
    conf.models[internalSelectedModel] &&
    conf.models[internalSelectedModel]["mediaInsightsPlus__impact_per_dimension_v1"];

  const prodImpactPerDimension =
    prodConf.models[internalSelectedModel] &&
    prodConf.models[internalSelectedModel]["mediaInsightsPlus__impact_per_dimension_v1"];
  const hasImpactPerDimensionChanged =
    impactPerDimension !== undefined && prodImpactPerDimension !== undefined
      ? checkImpactPerDimensionChanged(
          impactPerDimension,
          prodImpactPerDimension,
          prodConf.models[internalSelectedModel]["type"],
        )
      : false;
  // this array will be used for validating the null/empty values in the "data"
  const schemaFeatureToValidate = [
    "businessInsights__drivers",
    "driverCustom",
    "mediaGroupings",
    "mediaInsightsPlus__metrics_v1",
    "mediaInsights__metrics_v2",
  ];
  // only if model is selected
  if (selectedModelObject) {
    // use above list to check if the null values are present in the above features
    const schemaFeatureToValidateArray = schemaFeatureToValidate.map((d) => {
      const objectFromData = selectedModelObject[d] || [];
      if (d === "mediaGroupings" || d === "driverCustom") {
        // for grouping we are checking slug should not be empty
        return Boolean(objectFromData.filter((d) => !d.slug).length);
      } else {
        // for normal dropdown, check if element is undefiled or not
        return Boolean(objectFromData.filter((d) => d === undefined).length);
      }
    });
    nullValuesInConfig = Boolean(_.filter(schemaFeatureToValidateArray, (d) => d === true).length > 0);
  }
  const content = internalSelectedModel ? (
    <>
      <ConfigurationEditorModel
        organization={organization}
        orgaizationFrameworkId={conf.organization.modelFwOrgId}
        modelSlug={internalSelectedModel}
        isDisabled={!isDraft || loggingIn || !isClaim("ModelConfig.Write") || saveInProgress}
        data={conf.models[internalSelectedModel]}
        prodData={prodConf?.models?.[internalSelectedModel]}
        isFeatureAvailable={isFeatureAvailable}
        onChange={
          onChange &&
          ((localOnChange) =>
            onChange(
              produce((s) => {
                s.models[internalSelectedModel] = localOnChange(conf.models[internalSelectedModel]);
              }, {}),
            ))
        }
        actions={actions}
        saveInProgress={saveInProgress}
      />
      <ModelTitle>
        <IconButton
          data-test-id="admin-configuration-model-back-to-main"
          size="small"
          onClick={() => setInternalSelectedModel(null)}
          style={{
            borderRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            padding: 0,
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "20px" }} />
        </IconButton>
        <div>{`Model: ${conf.models[internalSelectedModel]?.brand} ${conf.models[internalSelectedModel]?.name}`}</div>
      </ModelTitle>
    </>
  ) : (
    <div style={{ backgroundColor: theme.palette.greyish[10] }}>
      <EditorItem
        topLevel
        data={conf.organization}
        schema={{
          type: "object",
          properties: {
            id: { title: "ID (Neo)", type: "string", ui__isSlug: true, ui__readOnly: true },
            modelFwOrgId: { title: "ID (Model Framework)", type: "string", ui__isSlug: true, ui__readOnly: true },
            createdAt: { title: "Organization created", type: "timestamp", ui__readOnly: true },
            updatedAt: { title: "Organization updated", type: "timestamp", ui__readOnly: true },
          },
        }}
        isDisabled
      />
      <Title
        large
        title="Models"
        description="Models are created and removed in Neo. Here you can set up frontend-specific configuration"
      />
      <div style={{ background: "white", margin: "0 -1px" }}>
        <TableLayout columns={1} extra={60}>
          {Object.entries(conf.models)
            .sort((a, b) => (`${a[1].brand} ${a[1].name}` > `${b[1].brand} ${b[1].name}` ? 1 : -1))
            .map(
              ([
                slug,
                { name, brand, socketSlug, objective = "missing objective", updatedAt, activeVersion, available },
              ]) => {
                const schema = schemaModels({ data: conf.models[slug] });
                const lastChangedMessage = readableStamp(updatedAt);
                const hasSocketSlug = socketSlug && socketSlug.length > 0;
                return (
                  <React.Fragment key={slug}>
                    <div
                      data-test-id={`admin-configuration-model-item-${slug}`}
                      style={{
                        padding: "8px 15px",
                        cursor: isClaim("ModelConfig.Read") ? "pointer" : "not-allowed",
                        fontSize: 14,
                      }}
                      onClick={() => isClaim("ModelConfig.Read") && setInternalSelectedModel(slug)}
                    >
                      <span style={!available ? { color: theme.palette.greyish[50] } : {}}>
                        {brand} {name}
                      </span>
                      <span style={{ marginLeft: 15, color: theme.palette.greyish[50] }}>{objective}</span>
                      <span
                        title="What Socket uses to refer to this model"
                        style={{
                          marginLeft: 15,
                          background: theme.palette.greyish[10],
                          color: hasSocketSlug ? theme.palette.greyish[50] : "red",
                          padding: "2px 4px",
                          borderRadius: 0,
                        }}
                      >
                        {hasSocketSlug ? socketSlug : "No socket slug!"}
                      </span>
                      {
                        /* Deactivated for now */ false && lastChangedMessage && (
                          <span style={{ marginLeft: 10, color: "#ffa000" }}>{lastChangedMessage}</span>
                        )
                      }
                      {activeVersion === "validation" && (
                        <span style={{ marginLeft: 10, color: theme.palette.alertRed.main }}>● Validation model</span>
                      )}
                    </div>
                    <EditorItem
                      data={available}
                      schema={schema.properties.available}
                      isDisabled={!isDraft || loggingIn || !isClaim("OrgConfig.Write") || saveInProgress}
                      onChange={(localOnChange) =>
                        onChange &&
                        onChange(
                          produce((s) => {
                            s.models[slug].available = localOnChange(available);
                          }, {}),
                        )
                      }
                      extraStyles={{ fontSize: 12 }}
                    />
                  </React.Fragment>
                );
              },
            )}
        </TableLayout>
      </div>
      <Title
        large
        title="Features"
        error={
          schemaFeatures({ data: conf.features, allModelSlugs, modelsLabels }).ui__isError &&
          schemaFeatures({ data: conf.features, allModelSlugs, modelsLabels }).ui__isError(conf.features)
        }
      />
      <div style={{ background: "white", margin: "0 -1px" }}>
        <EditorItem
          isDisabled={!isDraft || loggingIn || !isClaim("OrgConfig.Write") || saveInProgress}
          schema={schemaFeatures({ data: conf.features, allModelSlugs, modelsLabels })}
          data={conf.features}
          onChange={(localOnChange) =>
            onChange &&
            onChange(
              produce((s) => {
                s.features = localOnChange(s.features);
              }, {}),
            )
          }
        />
      </div>
    </div>
  );

  return (
    <>
      {content}

      {!isDraft && (
        <AdminBottomBox data-test-id="adminBottomBox">
          {prodUpdatedStamp && (
            <div style={{ color: `${theme.palette.greyish[80]}` }}>Last taken to production {prodUpdatedStamp}</div>
          )}
          {hasPrevDraft && draftUpdatedStamp && (
            <div>
              There's an <span style={{ fontWeight: 700 }}>ongoing draft</span>, saved {draftUpdatedStamp}
            </div>
          )}
          {(isClaim("OrgConfig.Write") || isClaim("ModelConfig.Write")) && (
            <HorizontalLayout style={{ margin: "8px 0px" }}>
              <Button
                data-test-id="admin-configuration-new-draft"
                disabled={hasPrevDraft}
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                disableElevation
                onClick={() => actions.edit().then(() => getAadClaims(organization, "validation"))}
                style={{ padding: "6px 8px", fontSize: "14px", height: "32px", display: "flex", alignItems: "center" }}
              >
                New draft
              </Button>

              {hasPrevDraft && (
                <Button
                  data-test-id="admin-configuration-open-draft"
                  variant="contained"
                  color="primary"
                  startIcon={<ScheduleIcon />}
                  disableElevation
                  onClick={() => actions.edit().then(() => getAadClaims(organization, "validation"))}
                  style={{
                    padding: "6px 8px",
                    fontSize: "14px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Open draft
                </Button>
              )}
            </HorizontalLayout>
          )}
        </AdminBottomBox>
      )}

      {isDraft && (
        <AdminBottomBoxValidation data-test-id="adminBottomBox">
          {hasPrevDraft && draftUpdatedStamp && <div>Draft saved {draftUpdatedStamp}</div>}
          {actions.takeToProd && isClaim("OrgConfig.Write") ? (
            <TakeToProductionButton
              data-test-id="admin-configuration-take-to-prod"
              disabled={loggingIn}
              onClick={() => actions.takeToProd().then(() => getAadClaims(organization, "prod"))}
              takeToProdInProgress={takeToProdInProgress}
              style={{ margin: "8px 0px", height: 32 }}
            />
          ) : (
            <HorizontalLayout style={{ margin: "8px 0px" }}>
              <Button
                data-test-id="admin-configuration-save-draft"
                startIcon={saveInProgress ? <CircularProgress size={20} /> : <SaveAltIcon />}
                variant="contained"
                style={{
                  background: "white",
                  color: loggingIn || !Boolean(actions.saveDraft) || nullValuesInConfig ? "lightgray" : "black",
                  height: 32,
                }}
                disabled={loggingIn || !Boolean(actions.saveDraft) || nullValuesInConfig || saveInProgress}
                onClick={() => {
                  saveDraftClickHandler(
                    organization,
                    actions,
                    setOpenImpactDimensionChangePopup,
                    isInvestmentPlanAvailable,
                    hasImpactPerDimensionChanged,
                  );
                }}
              >
                Save draft
              </Button>
              <ImpactDimensionChangePopup
                open={openImpactDimensionChangePopup}
                onClose={() => {
                  setOpenImpactDimensionChangePopup(false);
                }}
                confirmFn={() => {
                  actions.saveDraft().then(() => getAadClaims(organization, "validation"));
                }}
                resetFn={() => {
                  const isImpactReset = true;
                  actions
                    .saveDraft(isImpactReset, internalSelectedModel)
                    .then(() => getAadClaims(organization, "validation"));
                }}
              />
              {actions.discardChanges && (
                <div>
                  or{" "}
                  <Link
                    data-test-id="admin-configuration-discard-unsaved-changes"
                    component="button"
                    disabled={loggingIn}
                    onClick={actions.discardChanges}
                  >
                    discard unsaved changes
                  </Link>
                </div>
              )}
            </HorizontalLayout>
          )}

          <HorizontalLayout style={{ minHeight: 17 }}>
            {Boolean(actions.deleteDraft) && (
              <Link
                data-test-id="admin-configuration-delete-draft"
                component="button"
                disabled={loggingIn || deleteInProgress || saveInProgress || takeToProdInProgress}
                onClick={() => actions.deleteDraft().then(() => getAadClaims(organization, "prod"))}
                title="Delete draft: Your current changes will be lost"
                style={{ marginRight: 10 }}
              >
                {deleteInProgress && <CircularProgress size={15} style={{ marginRight: "10px" }} />}Delete draft
              </Link>
            )}
            {Boolean(actions.seeProd) && (
              <Link
                data-test-id="admin-configuration-back-to-prod"
                component="button"
                disabled={loggingIn || saveInProgress || deleteInProgress || takeToProdInProgress}
                onClick={() => actions.seeProd().then(() => getAadClaims(organization, "prod"))}
                title="Keep draft and go back to production"
              >
                See production version
              </Link>
            )}
          </HorizontalLayout>
        </AdminBottomBoxValidation>
      )}
    </>
  );
}

function saveDraftClickHandler(
  organization,
  actions,
  setOpenImpactDimensionChangePopup,
  isInvestmentPlanAvailable,
  hasImpactPerDimensionChanged,
) {
  if (isInvestmentPlanAvailable && hasImpactPerDimensionChanged) {
    setOpenImpactDimensionChangePopup(true);
  } else {
    actions.saveDraft().then(() => getAadClaims(organization, "validation"));
  }
}

const AdminBottomBox = styled.div`
  font-size: 14px;
  color: #000;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px 15px;
  background-color: #e5f2f1;
  border-radius: 0px 0px 0 0;
  border: 1px #b5e5e1 solid;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .MuiLink-button {
    color: inherit;
    text-decoration: underline;
  }
`;

const AdminBottomBoxValidation = styled(AdminBottomBox)`
  background: #ffa000;
  color: white;
  border: 1px #ffa000 solid;
`;

function TakeToProductionButton({ disabled, onClick, takeToProdInProgress }) {
  const theme = useTheme();
  const [confirmed, setConfirmed] = React.useState(false);

  return (
    <HorizontalLayout style={{ gap: 14 }}>
      <Button
        startIcon={takeToProdInProgress && <CircularProgress size={20} />}
        disabled={disabled || !confirmed}
        variant="contained"
        style={{
          background: "white",
          padding: "6px 8px",
          height: 32,
          display: "flex",
          gap: "8px",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <div style={{ height: 20, width: 20 }}>
          {!disabled && confirmed ? <span style={{ fontSize: "16px" }}>🚀</span> : <LaunchIcon />}
        </div>
        <span style={{ color: disabled || !confirmed ? theme.palette.greyish[25] : theme.palette.blackish.main }}>
          Take to production
        </span>
      </Button>

      <div style={{ textDecoration: "underline", display: "flex", alignItems: "center", gap: 10 }}>
        <Checkbox
          checked={confirmed}
          disabled={disabled}
          onChange={() => setConfirmed((s) => !s)}
          style={{ color: "white", padding: 0 }}
        />
        <span>I have tested my changes</span>
      </div>
    </HorizontalLayout>
  );
}

const HorizontalLayout = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

function readableStamp(lastChanged) {
  if (lastChanged) {
    const parsed = parseISO(lastChanged);
    if (isWithinInterval(parsed, { start: subDays(new Date(), 30), end: new Date() }))
      return (
        <>
          <OfflineBoltIcon fontSize="small" style={{ margin: "-4px 2px -6px 0" }} /> model updated{" "}
          {formatDistanceToNow(parsed)} ago
        </>
      );
  }
}

const ModelTitle = styled.div`
  background: white;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  position: absolute;
  top: 48px;
  left: 15px;
  border-radius: 0px;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.palette.greyish[25]};
`;
