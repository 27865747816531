import styled from "@emotion/styled/macro";
import { Button, IconButton, List, ListItem, Modal, Tooltip } from "@mui/material";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import React from "react";

//Icons
import { ReactComponent as CheckIcon } from "shared/Icons/check.svg";
import { ReactComponent as CrossIcon } from "shared/Icons/cross.svg";
import { ReactComponent as EditIcon } from "shared/Icons/edit-2.svg";
import { ReactComponent as DeleteRedIcon } from "shared/Icons/trash-red.svg";
import { compareFlatJSON, isValidName } from "../helpers";
import { getRoleLabel } from "shared/helpers/formatters";
import { getFeatureLabel } from "features";

const updateValidation = (isNew, isDisabled, armLevel, data, lastStandingAdminCheck) => {
  const response = { error: false, errorMessage: "" };
  if (isNew || isDisabled) return response;
  if (data?.firstName?.trim()?.length === 0)
    return { ...response, error: true, errorMessage: "Each user need to have a first name" };
  if (data?.firstName && !isValidName(data?.firstName))
    return { ...response, error: true, errorMessage: "Each user need a valid first name" };
  if (data?.lastName?.trim()?.length === 0)
    return { ...response, error: true, errorMessage: "Each user need to have a last name" };
  if (data?.lastName && !isValidName(data?.lastName))
    return { ...response, error: true, errorMessage: "Each user need a valid last name" };
  if (armLevel === "org" && data?.models?.length === 0)
    return { ...response, error: true, errorMessage: "Each user need to have access to atleast one model" };
  if (armLevel === "org" && data?.features?.length === 0)
    return { ...response, error: true, errorMessage: "Each user need to have access to atleast one feature" };
  // uncomment this for 1 org constraint.
  // if (armLevel === "tool" && data?.accessibleOrgs?.length === 0)
  //   return { ...response, error: true, errorMessage: "Every user needs an associated organization" };
  return response;
};

export function RowActions({
  isDisabled,
  isNew,
  dispatch,
  data,
  originalData,
  onDelete,
  onSave,
  armLevel,
  activeEmail = "",
  onActivate,
  modelsLabels,
}) {
  const [{ open, content, onConfirmation, method }, modalDispatch] = React.useReducer(modalReducer, {
    open: false,
    content: {},
  });

  const noChangesDone = _.isEqual(data, originalData);
  const changes = !noChangesDone
    ? compareFlatJSON(
        {
          name: originalData.firstName + " " + originalData.lastName,
          defaultRole: originalData.defaultRole,
          ...(originalData.orgRole && { orgRole: originalData.orgRole }),
          ...(originalData.accessibleOrgs && { accessibleOrgs: originalData.accessibleOrgs.map((b) => b.orgName) }),
          ...(originalData.models && { models: originalData.models }),
          ...(originalData.features && { features: originalData.features }),
        },
        {
          name: data.firstName + " " + data.lastName,
          defaultRole: data.defaultRole,
          ...(data.orgRole && { orgRole: data.orgRole }),
          ...(data.accessibleOrgs && { accessibleOrgs: data.accessibleOrgs.map((b) => b.orgName) }),
          ...(data.models && { models: data.models }),
          ...(data.features && { features: data.features }),
        },
      )
    : {};

  const onDiscard = (email) => {
    dispatch({ type: "make-disabled", originalData });
    onActivate("");
  };

  const name = `${data.firstName} ${data.lastName}`;

  const validation = React.useMemo(
    () => updateValidation(isNew, isDisabled, armLevel, data),
    [isNew, isDisabled, armLevel, data],
  );

  const makeEditable = () => {
    dispatch({ type: "make-editable" });
    onActivate(data.email);
  };
  return (
    <>
      <ActionsWrapper>
        {isDisabled && !isNew ? (
          activeEmail === "" ? (
            <Tooltip
              title={"Edit this user"}
              arrow
              //used to place the tooltip
              //https://popper.js.org/docs/v2/modifiers/offset/
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -6],
                      },
                    },
                  ],
                },
              }}
            >
              <ModifiedIconButton onClick={activeEmail === "" ? makeEditable : undefined}>
                <EditIcon height={20} width={20} />
              </ModifiedIconButton>
            </Tooltip>
          ) : null
        ) : (
          <>
            {onDelete && (
              <Tooltip
                title="Delete this user"
                arrow
                //used to place the tooltip
                //https://popper.js.org/docs/v2/modifiers/offset/
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -6],
                        },
                      },
                    ],
                  },
                }}
              >
                <ModifiedIconButton
                  onClick={() =>
                    isNew
                      ? onDelete(data.email)
                      : modalDispatch({
                          type: "delete",
                          description:
                            armLevel === "tool" ? (
                              <p style={{ whiteSpace: "pre-line" }}>
                                Are you sure you want to delete <Highlight>{name}</Highlight>? <br />
                                This user will be deleted from all organizations.
                              </p>
                            ) : (
                              <p>
                                Are you sure you want to delete <Highlight>{name}</Highlight> from the organization{" "}
                                <Highlight>{data.org}</Highlight>?
                              </p>
                            ),
                          onConfirmation: () => {
                            onDelete(data.email);
                            onActivate("");
                          },
                        })
                  }
                  data-test-id="delete-user"
                >
                  <DeleteRedIcon height={20} width={20} />
                </ModifiedIconButton>
              </Tooltip>
            )}
            {!isNew && (
              <>
                <Tooltip
                  title="Discard changes"
                  arrow
                  //used to place the tooltip
                  //https://popper.js.org/docs/v2/modifiers/offset/
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -6],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <ModifiedIconButton
                    onClick={() =>
                      noChangesDone || _.isEmpty(changes)
                        ? onDiscard(data.email)
                        : modalDispatch({
                            type: "discard",
                            name,
                            onConfirmation: onDiscard,
                          })
                    }
                  >
                    <CrossIcon height={20} width={20} />
                  </ModifiedIconButton>
                </Tooltip>
                <Tooltip
                  title={
                    noChangesDone || _.isEmpty(changes)
                      ? "No changes done"
                      : !validation.error
                        ? "Save current changes"
                        : validation.errorMessage
                  }
                  arrow
                  //used to place the tooltip
                  //https://popper.js.org/docs/v2/modifiers/offset/
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -6],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <ModifiedIconButton
                    onClick={() =>
                      noChangesDone || _.isEmpty(changes)
                        ? () => {
                            onDiscard();
                            onActivate("");
                          }
                        : !validation.error &&
                          modalDispatch({
                            type: "edit",
                            name,
                            description: (
                              <EditContent changes={changes} originalData={originalData} modelsLabels={modelsLabels} />
                            ),
                            onConfirmation: () => {
                              onSave(data);
                              onActivate("");
                            },
                          })
                    }
                  >
                    <CheckIcon
                      style={{
                        opacity: noChangesDone || _.isEmpty(changes) || validation.error ? 0.25 : 1,
                        height: 20,
                        width: 20,
                      }}
                    />
                  </ModifiedIconButton>
                </Tooltip>
              </>
            )}
          </>
        )}
      </ActionsWrapper>
      {!isDisabled && !isNew && (
        <ConfirmationDialog
          open={open}
          onClose={() => modalDispatch({ type: "close" })}
          content={content}
          onConfirmation={onConfirmation}
          method={method}
        />
      )}
    </>
  );
}

export function modalReducer(state, action) {
  switch (action.type) {
    case "close": {
      return { open: false, content: {} };
    }
    case "delete": {
      return {
        open: true,
        content: {
          heading: "Delete User",
          action: "Delete",
          description: action.description,
        },
        onConfirmation: action.onConfirmation,
      };
    }
    case "edit": {
      return {
        open: true,
        content: {
          heading: "Changes to the user’s profile",
          action: "SAVE",
          description: action.description,
        },
        onConfirmation: action.onConfirmation,
        method: "EDIT",
      };
    }
    case "discard": {
      return {
        open: true,
        content: {
          heading: "Discard Changes",
          action: "Discard",
          description: (
            <p>
              Are you sure you want to discard your current changes for <Highlight>{action.name}</Highlight>?
            </p>
          ),
        },
        onConfirmation: action.onConfirmation,
      };
    }

    default: {
      return { ...state };
    }
  }
}

const ActionsWrapper = styled.div`
  flex-direction: row-reverse;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  /* padding: 0 10px; */
`;

const ModifiedIconButton = styled(IconButton)`
  height: 24px;
  width: 24px;
  padding: 0px;
`;

const Highlight = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  font-size: 14px;
`;

function ConfirmationDialog({ open, onClose, onConfirmation, content, method }) {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Brown, Helvetica, sans-serif",
      }}
    >
      <ModalContent style={{ width: 510 }}>
        <h1>{content.heading}</h1>
        {content.description}
        <ModalActions style={{ height: "auto" }}>
          <Button
            style={{
              backgroundColor: method === "EDIT" ? theme.palette.primary.main : theme.palette.alertRed.main,
              color: "white",
            }}
            onClick={() => {
              onConfirmation();
              onClose();
            }}
          >
            {content.action}
          </Button>
          <Button style={{ backgroundColor: "transparent", color: "black" }} onClick={onClose}>
            NO
          </Button>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
}

const EditContent = ({ changes, originalData, modelsLabels }) => {
  const getModelLabel = (slug) => modelsLabels?.[slug] || slug;
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px", fontSize: "14px" }}>
      <p>
        For the user <Highlight>{`${originalData.firstName || ""} ${originalData.lastName || ""}`}</Highlight>, you have
        changed:
      </p>

      <List
        className="customScroll"
        sx={{
          listStyleType: "disc",
          p: "0px 30px",
          fontSize: "14px",
          fontWeight: 300,
          maxHeight: "60vh",
          overflow: "auto",
        }}
      >
        {changes.name && (
          <ListItem sx={{ display: "list-item" }} disableGutters disablePadding>
            <strong>Name: </strong> {changes.name.oldValue} →{" " + changes.name.newValue}
          </ListItem>
        )}
        {changes.defaultRole && (
          <ListItem sx={{ display: "list-item" }} disableGutters disablePadding>
            <strong>Role: </strong> {getRoleLabel(changes.defaultRole.oldValue)} →
            {" " + getRoleLabel(changes.defaultRole.newValue)}
          </ListItem>
        )}
        {changes.orgRole && (
          <ListItem sx={{ display: "list-item" }} disableGutters disablePadding>
            <strong>Role: </strong> {getRoleLabel(changes.orgRole.oldValue)} →
            {" " + getRoleLabel(changes.orgRole.newValue)}
          </ListItem>
        )}
        {changes.accessibleOrgs && (
          <ListItem sx={{ display: "list-item" }} disableGutters disablePadding>
            <strong>Organizations: </strong>
            {changes.accessibleOrgs.added && `access given to ${changes.accessibleOrgs.added.join(", ")}`}
            {changes.accessibleOrgs.added && changes.accessibleOrgs.removed && " and "}
            {changes.accessibleOrgs.removed && `revoked access to ${changes.accessibleOrgs.removed.join(", ")}`}
          </ListItem>
        )}
        {changes.models && (
          <ListItem sx={{ display: "list-item" }} disableGutters disablePadding>
            <strong>Models: </strong>
            {changes.models.added && `access given to ${changes.models.added.map((a) => getModelLabel(a)).join(", ")}`}
            {changes.models.added && changes.models.removed && " and "}
            {changes.models.removed &&
              `revoked access to ${changes.models.removed.map((a) => getModelLabel(a)).join(", ")}`}
          </ListItem>
        )}
        {changes.features && (
          <ListItem sx={{ display: "list-item" }} disableGutters disablePadding>
            <strong>Features: </strong>
            {changes.features.added &&
              `access given to ${changes.features.added.map((f) => getFeatureLabel(f)).join(", ")}`}
            {changes.features.added && changes.features.removed && " and "}
            {changes.features.removed &&
              `revoked access to ${changes.features.removed.map((f) => getFeatureLabel(f)).join(", ")}`}
          </ListItem>
        )}
      </List>

      <p>Save these changes?</p>
    </div>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: white;
  width: fit-content;
  color: black;

  h1 {
    font-size: 16px;

    //resets
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  p {
    font-size: 14px;

    //resets
    margin: 0;
    padding: 0;
  }
`;

const ModalActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
  button {
    font-size: 14px;
    padding: 10px;
    height: 32px;
  }
`;
