import styled from "@emotion/styled/macro";
import { Tooltip } from "@mui/material";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import React from "react";

import EditorItem from "admin/EditorItem";
import { RowActions } from "admin/CommonComponents/UserRowActions";
import { isValidEmail } from "admin/helpers";
import { useAccount } from "auth";
import DropdownMenu from "shared/components/DropdownMenu";
import { getRoleLabel } from "shared/helpers/formatters";
import { roles } from ".";
import EmailAutoComplete from "./EmailAutoComplete";
import { EMPTY_NEW_USER } from "./index";
import { getFeatureLabel } from "features";

//Icons

export const defaultToOrgRole = {
  //defaultRole to orgRole map
  superAdmin: "admin",
  deliveryLead: "admin",
  deliveryTeam: "deliveryTeam",
  client: "client",
  clientService: "clientService",
  developer: "developer",
};

export default function Entry({
  isNew,
  data,
  onChange,
  onDelete,
  globallyAvailableModels,
  globallyAvailableFeatures,
  modelsLabels,
  touched,
  organization,
  activeEmail = "",
  onActivate,
  existingEmails,
  index,
  existInToolEmails = [],
  setExistInToolEmails,
}) {
  const [{ isDisabled, localData }, dispatch] = React.useReducer(localDataReducer, {
    isDisabled: isNew ? false : true, //New item is editable
    localData: data,
  });

  const originalData = _.cloneDeep(data);
  const theme = useTheme();
  const { isClaim } = useAccount();

  const [autoCompleteEmail, setAutoCompleteEmail] = React.useState("");
  if (!isDisabled && !isNew) {
    //data to use localData if we are in editing mode
    data = localData;
  }
  const stringifiedData = JSON.stringify(data);
  // This is to sync with the bulk assign feature when the data changes from the top level and local data gets out of sync.
  React.useEffect(() => {
    const data = JSON.parse(stringifiedData);
    dispatch({ type: "update-local-data", originalData: { ...data } });
  }, [stringifiedData]);

  const options = isNew
    ? autoCompleteEmail?.defaultRole === "developer"
      ? [
          { id: "developer", label: "Developer" },
          { id: "admin", label: "Admin" },
        ]
      : autoCompleteEmail?.defaultRole === "deliveryTeam"
        ? [
            { id: "deliveryTeam", label: "Delivery Team" },
            { id: "admin", label: "Admin" },
          ]
        : roles
    : data?.defaultRole === "deliveryTeam"
      ? [
          { id: "deliveryTeam", label: "Delivery Team" },
          { id: "admin", label: "Admin" },
        ]
      : data?.defaultRole === "developer"
        ? [
            { id: "developer", label: "Developer" },
            { id: "admin", label: "Admin" },
          ]
        : [];

  const isExistingEmail = existInToolEmails.includes(data.email);
  const isKantarEmail = data.email.toLowerCase().endsWith("@kantar.com");
  const hasDefaultRole = typeof autoCompleteEmail?.defaultRole !== "undefined";
  const hasDefaultRoleAndCannotPromote =
    hasDefaultRole &&
    autoCompleteEmail?.defaultRole !== "deliveryTeam" &&
    autoCompleteEmail?.defaultRole !== "developer";
  const hasFirstName = typeof autoCompleteEmail?.firstName !== "undefined";
  const hasLastName = typeof autoCompleteEmail?.lastName !== "undefined";
  const isCurrentSessionUser = data.email === sessionStorage.getItem("msalUsername");
  const isDeveloperOrDeliveryTeam = data?.defaultRole === "developer" || data?.defaultRole === "deliveryTeam";

  const RoleEditor = (
    <Tooltip
      title={
        activeEmail === "" || activeEmail === data.email
          ? !isNew
            ? isDeveloperOrDeliveryTeam
              ? "Role can be promoted/demoted"
              : "Role cannot be updated"
            : isValidEmail(data.email) && !isExistingEmail && !isKantarEmail
              ? "External user's role is restricted to client"
              : hasDefaultRoleAndCannotPromote && "Role cannot be updated for existing user"
          : ""
      }
      placement="top"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -12],
              },
            },
          ],
        },
      }}
      arrow
      disableFocusListener
      disableInteractive
    >
      <TooltipWrapper>
        {isDisabled ? (
          <DisabledText>
            {hasDefaultRoleAndCannotPromote
              ? defaultToOrgRole[autoCompleteEmail.defaultRole]
              : data.orgRole === ""
                ? "Organization Role"
                : getRoleLabel(data.orgRole)}
          </DisabledText>
        ) : (
          <DropdownMenu
            value={data.orgRole === "" ? "Organization Role" : getRoleLabel(data.orgRole) + "test"}
            selected={
              hasDefaultRoleAndCannotPromote
                ? defaultToOrgRole[autoCompleteEmail.defaultRole]
                : data.orgRole === ""
                  ? "Organization Role"
                  : getRoleLabel(data.orgRole)
            }
            onChange={(newRole) =>
              isNew ? onChange({ ...data, orgRole: newRole }) : dispatch({ type: "change-role", orgRole: newRole })
            }
            options={options}
            hideArrowOnDisable={true}
            fixedWidth={data.orgRole === "" ? "100%" : 180}
            iconSize="big"
            clickDisabled={
              isDisabled ||
              !(isNew || isDeveloperOrDeliveryTeam) ||
              isCurrentSessionUser ||
              (isNew && (isExistingEmail || (!isExistingEmail && !isKantarEmail))) ||
              hasDefaultRoleAndCannotPromote
            }
            style={{
              container: { fontSize: "inherit", height: "100%" },
              button: {
                border: "none",
                height: "100%",
                fontSize: isNew ? "12px" : "14px",
                textTransform: isNew ? "uppercase" : undefined,
                padding: "0px 10px",
                color: isNew
                  ? (isExistingEmail || (!isExistingEmail && !isKantarEmail) || hasDefaultRoleAndCannotPromote) &&
                    "#858574"
                  : (isCurrentSessionUser || !isDeveloperOrDeliveryTeam) && "#858574",
              },
              buttonText: {
                opacity: 1,
                color: data.orgRole === "" && theme.palette.greyish[50],
              },
            }}
          />
        )}
      </TooltipWrapper>
    </Tooltip>
  );
  const featureLabels = React.useMemo(() => {
    return globallyAvailableFeatures?.reduce((acc, feature) => ({ ...acc, [feature]: getFeatureLabel(feature) }), {});
  }, [globallyAvailableFeatures]);
  return (
    <EntryRow
      disabled={isDisabled}
      theme={theme}
      isNew={isNew}
      active={activeEmail === "" || activeEmail === data.email}
    >
      {!isNew ? (
        <Tooltip
          title={
            activeEmail === "" || activeEmail === data.email ? (
              <>
                To change the email <br /> delete and add again
              </>
            ) : (
              ""
            )
          }
          //used to place the tooltip
          //https://popper.js.org/docs/v2/modifiers/offset/
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -12],
                  },
                },
              ],
            },
          }}
          arrow
        >
          <TooltipWrapper>
            {isDisabled ? (
              <DisabledText>{data.email}</DisabledText>
            ) : (
              <EditorItem
                withinTable
                isDisabled={!isNew}
                schema={{ title: "Email ID", type: "string", ui__isSlug: true }}
                data={data.email}
                onChange={(localOnChange) =>
                  isNew
                    ? onChange({ ...data, email: localOnChange(data.email)?.toLowerCase()?.trim() })
                    : dispatch({ type: "change-email", email: localOnChange(data.email)?.toLowerCase()?.trim() })
                }
                extraStyles={{ padding: 0, paddingLeft: isNew && 5, fontSize: "14px" }}
              />
            )}
          </TooltipWrapper>
        </Tooltip>
      ) : (
        <EmailAutoComplete
          key={index}
          id={data?.id}
          existingEmails={existingEmails}
          value={autoCompleteEmail}
          setAlreadyExistsInTool={(a) => {
            a
              ? setExistInToolEmails((prevArray) => [...prevArray, autoCompleteEmail?.email?.toLowerCase()])
              : setExistInToolEmails((prevArray) =>
                  prevArray.filter((element) => element !== autoCompleteEmail?.email?.toLowerCase()),
                );
          }}
          updateValue={(a) => {
            setAutoCompleteEmail(a);
            a !== null
              ? onChange({
                  ...data,
                  ...a,
                  ...(a.defaultRole && { orgRole: defaultToOrgRole[a.defaultRole] }),
                  ...(isValidEmail(a.email) && a.email.toLowerCase().endsWith("@kantar.com")
                    ? {
                        models: globallyAvailableModels,
                        features: globallyAvailableFeatures,
                      }
                    : {
                        orgRole: "client",
                      }),
                })
              : onChange({ ...data, ...EMPTY_NEW_USER });
          }}
          extraStyles={{
            fontSize: "12px",
            padding: "10px 0px 10px 5px",
            color: theme.palette.greyish[50],
          }}
          placeholderStyle={{ opacity: 1, textTransform: "uppercase", color: theme.palette.greyish[50] }}
        />
      )}
      {isDisabled ? (
        <DisabledText style={{ color: "#000" }}>
          {hasFirstName ? autoCompleteEmail.firstName : data.firstName}
        </DisabledText>
      ) : (
        <EditorItem
          withinTable
          schema={{ title: "First Name", type: "string" }}
          data={hasFirstName ? autoCompleteEmail.firstName : data.firstName}
          isDisabled={isDisabled || isCurrentSessionUser || hasFirstName}
          onChange={(localOnChange) =>
            isNew
              ? onChange({ ...data, firstName: localOnChange(data.firstName) })
              : dispatch({ type: "change-firstname", firstName: localOnChange(data.firstName) })
          }
          extraStyles={{
            padding: 0,
            paddingLeft: isNew && 5,
            fontSize: isNew ? "12px" : "14px",
            textTransform: isNew ? "uppercase" : undefined,
            color: isNew ? theme.palette.greyish[50] : activeEmail === "" && "black",
          }}
          placeholderStyle={{ opacity: 1, color: theme.palette.greyish[50] }}
        />
      )}
      {isDisabled ? (
        <DisabledText style={{ color: "#000" }}>
          {hasLastName ? autoCompleteEmail.lastName : data.lastName}
        </DisabledText>
      ) : (
        <EditorItem
          withinTable
          schema={{ title: "Last Name", type: "string" }}
          data={hasLastName ? autoCompleteEmail.lastName : data.lastName}
          isDisabled={isDisabled || isCurrentSessionUser || hasLastName}
          onChange={(localOnChange) =>
            isNew
              ? onChange({ ...data, lastName: localOnChange(data.lastName) })
              : dispatch({ type: "change-lastname", lastName: localOnChange(data.lastName) })
          }
          extraStyles={{
            padding: 0,
            paddingLeft: isNew && 5,
            fontSize: isNew ? "12px" : "14px",
            color: isNew ? theme.palette.greyish[50] : activeEmail === "" && "black",
            textTransform: isNew ? "uppercase" : undefined,
          }}
          placeholderStyle={{ opacity: 1, color: theme.palette.greyish[50] }}
        />
      )}

      <EditorItem
        withinTable
        schema={{
          title: "Models",
          description: "Models this user can see",
          type: "array-v2",
          items: { type: "string", ui__isSlug: true, enum: globallyAvailableModels, ui__labels: modelsLabels },
          default: [],
        }}
        isNew={isNew}
        showLabel={false}
        data={data.models}
        isDisabled={isDisabled}
        onChange={(localOnChange) =>
          isNew
            ? onChange({ ...data, models: localOnChange(data.models) })
            : !isDisabled && dispatch({ type: "change-models", models: localOnChange(data.models) })
        }
        extraStyles={{
          fontSize: isNew ? "12px" : "14px",
          padding: "0 15px",
          textTransform: isNew ? "uppercase" : undefined,
          color: isNew && theme.palette.greyish[50],
        }}
      />
      <EditorItem
        withinTable
        schema={{
          title: "Features",
          description: "Features this user can see",
          type: "array-v2",
          items: { type: "string", ui__isSlug: true, enum: globallyAvailableFeatures, ui__labels: featureLabels },
          default: [],
        }}
        showLabel={false}
        data={data.features}
        isDisabled={isDisabled}
        onChange={(localOnChange) =>
          isNew
            ? onChange({ ...data, features: localOnChange(data.features) })
            : !isDisabled && dispatch({ type: "change-features", features: localOnChange(data.features) })
        }
        isNew={isNew}
        extraStyles={{
          fontSize: isNew ? "12px" : "14px",
          padding: "0 15px",
          textTransform: isNew ? "uppercase" : undefined,
          color: isNew && theme.palette.greyish[50],
        }}
      />
      {RoleEditor}

      {isClaim("OrgARM.Write") && (
        <RowActions
          isDisabled={isDisabled}
          isNew={isNew}
          dispatch={dispatch}
          data={data}
          originalData={originalData}
          onDelete={
            onDelete && data.email !== sessionStorage.getItem("msalUsername")
              ? () => {
                  onDelete(data.email);
                  setAutoCompleteEmail("");
                }
              : false
          }
          onSave={(data) => {
            onChange(data);
            dispatch({ type: "make-disabled" });
          }}
          armLevel="org"
          activeEmail={activeEmail}
          onActivate={onActivate}
          modelsLabels={modelsLabels}
        />
      )}
    </EntryRow>
  );
}

function localDataReducer(state, action) {
  switch (action.type) {
    case "make-editable":
      return { ...state, isDisabled: false };

    case "make-disabled":
      return { ...state, isDisabled: true, ...(action.originalData ? { localData: action.originalData } : {}) };

    case "change-email":
      return { ...state, localData: { ...state.localData, email: action.email } };

    case "change-firstname":
      return { ...state, localData: { ...state.localData, firstName: action.firstName } };

    case "change-lastname":
      return { ...state, localData: { ...state.localData, lastName: action.lastName } };

    case "change-models":
      return { ...state, localData: { ...state.localData, models: action.models } };

    case "change-features":
      return { ...state, localData: { ...state.localData, features: action.features } };

    case "change-role":
      return { ...state, localData: { ...state.localData, orgRole: action.orgRole } };

    case "update-local-data":
      return { ...state, localData: { ...action.originalData } };

    default:
      return { ...state };
  }
}

const EntryRow = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: ${({ isNew }) =>
    isNew ? "var(--org-new-entry-grid-columns)" : "var(--org-entry-grid-columns)"};
  // padding: ${({ isNew }) => !isNew && "4px 0px"};
  font-size: 16px;
  border: 1px solid transparent;
  border-bottom: ${({ isNew }) => (isNew ? undefined : "1px solid lightgray")};
  border: ${({ disabled, theme, isNew }) =>
    disabled || isNew ? undefined : `1px solid ${theme.palette.primary.main}`};
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  align-items: center;
  height: 40px;
`;

//Wrapping custom components in tooltip doesn't work unless we Forward the ref and props in the custom component, this is a workaround to wrap the custom components so that Tooltip show properly.
const TooltipWrapper = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const DisabledText = styled.span`
  margin: 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.greyish[50]};
  font-size: 14px;
  width: calc(100% - 30px);
  white-space: nowrap;
`;
